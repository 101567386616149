import { BrowserRouter, Route, Routes  } from "react-router-dom";
import Redirect from "./Redirect.component";

/**
 * Router containing the redirect routes for tercera.nl
 * @returns 
 */
const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/support" element={ <Redirect to="https://xxllnc.nl/support" /> } />
        <Route path="/contact" element={ <Redirect to="https://xxllnc.nl/contact" /> } />
        <Route path="/webinar" element={ <Redirect to="https://docs.google.com/forms/d/e/1FAIpQLSdN0VituwFDNg9tqEmUbKW5RngUA_-yCv1S8fDl69ikhcUK0g/viewform" /> } />
        <Route path="/" element={ <Redirect to="https://xxllnc.nl/teams/omgeving" /> }/>
        <Route path="*" element={ <Redirect to="https://xxllnc.nl/teams/omgeving" /> }/>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
