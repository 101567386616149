import { useEffect } from "react";

/**
 * Component that takes a URL that will be used to redirect the user to
 */
const Redirect = (props: { to: string }) => {
    const { to } = props;

    /** 
     * Redirect logic
    */
    useEffect(() => {
        window.location.href = to;
    }, [to]);

    return null;
};

export default Redirect;
